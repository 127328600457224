<!-- A设备管理-巡检管理-巡检标准-标准详情 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">点检标准</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_20 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="点检工具：">
                    <el-input v-model="formInline.inspTool" placeholder="" class="width_180 " disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="记录人：">
                    <el-input v-model="formInline.oprPerson" placeholder="" class="width_180 " disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="点检标准名称：">
                    <el-input v-model="formInline.inspName" placeholder="" class="width_180 " disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="点检标准类型：">
                     <el-input v-model="formInline.InspStdType_name" placeholder="" class="width_180 " disabled></el-input>
                    <!-- <el-select v-model="formInline.InspStdType" placeholder="" class="width_180 " disabled>
                        <el-option v-for="item in inspectionStandardType" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                    <!-- <el-input value="一般点检" v-if="formInline.InspStdType == 0" placeholder="" class="width_180 " disabled></el-input>
                    <el-input value="专业点检" v-if="formInline.InspStdType == 1" placeholder="" class="width_180 " disabled></el-input> -->
                </el-form-item>
            </el-form>
            <el-form :model="formInline" label-width="110px" class="flex flex_wrap " style="width: 65%;">
                <el-form-item label="点检目的：">
                    <el-input v-model="formInline.inspTarget" class="width_1100" type="textarea" :rows="3"
                        disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="备注：">
                    <el-input v-model="formInline.remarks" class="width_1100" type="textarea" :rows="3"
                        disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
            </el-form>

            <!-- table -->
            <div style="width: 65%;" class="height_270">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class=" " height="100%"
                    border style="width: 100%;">
                    <!-- <el-table-column type="index" width="55"></el-table-column> -->
                    <!-- <el-table-column prop="inspDetailType" label="类型详情标准" width=""></el-table-column> -->
                    <el-table-column label="类型详情标准">
                        <template slot-scope="scope">
                            <span v-if="scope.row.inspDetailType == 0">布尔型</span>
                            <span v-if="scope.row.inspDetailType == 1">数值型</span>
                            <span v-if="scope.row.inspDetailType == 2">图片型</span>
                            <span v-if="scope.row.inspDetailType == 3">勾选型</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="inspDetailCont" label="标准详情内容" width=""></el-table-column>
                    <el-table-column prop="inspDetailStandard" label="标准详情标准" width="">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                v-if="scope.row.inspDetailType == 2">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="lookImg(scope.row.inspDetailStandard)">查看</span>
                            </el-button>
                            <span v-else>{{scope.row.inspDetailStandard}}</span>
                            <!-- <span>{{scope.row.inspDetailStandard}}</span> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width=""></el-table-column>
                    <!-- <el-table-column prop="name" label="上限" width=""></el-table-column>
                <el-table-column prop="name" label="默认值" width=""></el-table-column> -->
                </el-table>
            </div>


            <div class="flex mg_top_20 mg_bottom_20">
                <div>
                    <el-button type="primary" class="width_140" @click="goback">返回</el-button>
                </div>
            </div>
        </div>
         <!-- 查看图片 -->
            <el-dialog title="查看图片" :visible.sync="dialogLookImg" width="30%" top="20vh">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">查看图片</div> -->
                <img :src="imgUrl" alt="" class="imgLook">
            </el-dialog>
    </div>
</template>

<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        inspectionStandardTypeQuery,
        inspectionStandardQuery,
        uploadInspectionImage,
        inspectionStandardDetailQuery,
        inspectionStandardDetailQueryLimit,
        inspectionLoadImage,
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                checked: false,
                tableData: [],
                inspDetailJSON: {
                    "array": []
                },
                formInline: {
                    inspTool: '', //点检工具
                    //点检详情
                    inspName: '', //点检标准名称
                    inspTarget: '', //点检目的
                    oprPerson: '', //记录人
                    InspStdType: '', //点检标准类型
                    deviceID: '', //设备编号
                    deviceName: '', //设备名称
                    //用户编号
                    remarks: '', //备注
                    inspStdID: ''
                },
                xx: '',
                input2: '',
                fileList: [{
                    name: 'food.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                }, ],
                detailID: '',
                inspectionStandardType: [],
                formStandard: {
                    inspDetailType: '',
                    inspDetailCont: '',
                    inspDetailStandard: '',
                    remarks: '',
                },
                formLabelWidth: '120px',
                readOnly: false,
                standardTypeDetails: [{
                    value: '0',
                    label: '布尔型'
                }, {
                    value: '1',
                    label: '数值型'
                }, {
                    value: '2',
                    label: '图片型'
                }, {
                    value: '3',
                    label: '勾选型'
                }],
                imageUrl: '',
                permission: {},
                userID: '',
                currentPage: 1,
                pageSize: 2,
                pageQuantity: 0,
                dialogTableVisible: false,


                organizationValue: [],
                organization: [],
                standardArr: [],
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                checkData: {},
                equInfoList: [],
                currentEqu: [],
                imgUrl: '',
                dialogLookImg: false,
            }
        },
        mounted() {
            if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                // this.maintainOrderQuery();//此处是根据ID查询数据详情
            }
            this.inspectionStandardTypeQuery();
            this.inspectionStandardQuery();
            //this.inspectionStandardDetailQuery();
        },
        methods: {
            goback() {
                this.$router.go(-1);
            },
            // 点检标准类型
            async inspectionStandardTypeQuery() {
                const selectRes = await inspectionStandardTypeQuery();
                this.inspectionStandardType = selectRes.rows;
                // this.formInline.InspStdType = selectRes.rows[0].value;
            },
            async inspectionStandardQuery() {
                var param = {
                    ID: this.detailID
                }
                //console.log(param)

                const res = await inspectionStandardQuery(param);
                // console.log(res)

                this.formInline = res.rows[0]
                //console.log(res.rows[0])
                console.log(this.formInline)

                this.inspectionStandardDetailQuery();
            },
            async inspectionStandardDetailQuery() {
                var param = {
                    //ID:this.detailID,
                    inspStdID: this.formInline.inspStdID
                }
                console.log(param)
                const res = await inspectionStandardDetailQuery(param);
                console.log(res)

                this.tableData = res.rows

                //this.formStandard = res.rows;

            },
             async lookImg(path) {
                // inspectionLoadImage
                var param = {
                    path: path
                }
                const res = await inspectionLoadImage(param);
                this.imgUrl = 'data:image/' + res.format + ';base64,' + res.content;
                this.dialogLookImg = true
            },
        }

    }
</script>
<style scoped>
    .avatar-uploader {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        /* width: 200px;
        height: 200px; */
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .avatar {
        width: 200px;
        height: 200px;
        display: block;
    }

    .standard_h {
        height: 430px;
    }

    .detailStandard_h {
        height: 150px;
        overflow-y: overlay;
    }

    .imgLook {
        width: 300px;
        height: 300px;
        margin: 20px auto;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    /* ::v-deep .el-upload {
        width: 100%;
    } */
</style>